import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Page = ({ data }) => (
  <Layout>
    <SEO title="Curriculum Vitae" />
    <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}></div>
  </Layout>
)

export const query = graphql`
  query {
    wordpressPage(id: { eq: "fd10fc35-5952-56c4-a035-40ad714426f0" }) {
      content
    }
  }
`

export default Page
